<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon
              type="fal"
              name="long-arrow-left"
              size="24"
              colorFont="#cfd2dd"
            />
          </a>
          <h1>Campanhas</h1>
        </div>

        <!-- boxapp body -->
        <div class="box-main">
          <div class="section projects-list">
            <v-layout justify-end w-100 v-if="companyId">
              <v-btn
                flat
                round
                color="white"
                class="btn-primary flex-inherit"
                large
                @click="
                  $router.push({
                    name: routes.app.CampaignEdit,
                    query: { companyId: companyId },
                  })
                "
              >
                <i class="fas fa-plus mr-2"></i> Nova Campanha
              </v-btn>
            </v-layout>

            <CampaignItem
              v-if="campaignList.Nova && campaignList.Nova.length > 0"
              :currentPhase="campaignPhasesText.Nova"
              :list="campaignList.Nova"
              :entityId="entityId"
              @refresh="refreshList"
            />

            <CampaignItem
              v-if="
                campaignList.EmAndamento && campaignList.EmAndamento.length > 0
              "
              :currentPhase="campaignPhasesText.EmAndamento"
              :list="campaignList.EmAndamento"
              :entityId="entityId"
              @refresh="refreshList"
            />

            <CampaignItem
              v-if="campaignList.Pausada && campaignList.Pausada.length > 0"
              :currentPhase="campaignPhasesText.Pausada"
              :list="campaignList.Pausada"
              :entityId="entityId"
              @refresh="refreshList"
            />

            <CampaignItem
              v-if="campaignList.Encerrada && campaignList.Encerrada.length > 0"
              :currentPhase="campaignPhasesText.Encerrada"
              :list="campaignList.Encerrada"
              :entityId="entityId"
              @refresh="refreshList"
            />

            <CampaignItem
              v-if="campaignList.Cancelada && campaignList.Cancelada.length > 0"
              :currentPhase="campaignPhasesText.Cancelada"
              :list="campaignList.Cancelada"
              :entityId="entityId"
              @refresh="refreshList"
            />
          </div>
        </div>
      </div>
      <div class="box-help-spacer"></div>
      <HelpBox :text="helpBoxText" />
    </v-layout>
  </div>
</template>

<script type="plain/text">
import FooterStep from "@/components/FooterStep.vue";
import CampaignItem from "@/components/CampaignItem.vue";
import ProjectService from "@/scripts/services/project.service";
import CampaignService from "@/scripts/services/campaign.service";
import HelpBox from "@/components/HelpBox.vue";
import { CampaignPhasesText } from "@/scripts/models/enums/campaignPhases.enum.js";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import { Routes } from "@/scripts/models/enums/routes.enum";
import Permissions from "@/scripts/models/enums/permissions.enum";
import store from "@/store.js";
export default {
  components: {
    HelpBox,
    CampaignItem,
  },
  props: ["pageOptions"],
  data() {
    return {
      entityId: null,
      companyId: null,
      helpBoxText: HelpBoxText.CampaignList,
      campaignPhasesText: CampaignPhasesText,
      campaignService: new CampaignService(),
      loggedUser: {},
      campaignList: {},
      routes: Routes,
      breadcrumbLevels: [{ text: "Início" }, { text: "Campanha" }],
    };
  },
  beforeRouteEnter: (to, from, next) => {
    if (to.query.entityId && to.query.entityId != 0) {
      if (
        store.getters.hasPermission(
          [Permissions.CRUDCampaign],
          to.query.entityId
        ) ||
        store.getters.hasPermission([Permissions.CRUDCampaignAll])
      ) {
        next();
      } else {
        store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Você não tem permissão para acessar essa página."
        );
        next(false);
      }
    } else {
      next({ name: Routes.app.DashboardPF });
    }
  },
  created() {
    if (this.$route.query.auc) {
      var url =
        location.protocol +
        "//" +
        location.host +
        location.pathname +
        "?entityId=" +
        this.$route.query.entityId;
      location.href = url;
    }
    this.entityId = this.$route.query.entityId;
    this.companyId = this.$route.query.companyId;
    this.refreshList();
    this.$emit("changeLevels", this.breadcrumbLevels);
    this.pageOptions.changeClass(null);
  },
  //remove footer
  mounted() {
    this.addClass = this.additionalClass;
    this.changeClass();
  },
  methods: {
    refreshList() {
      this.campaignService.listAll(this.entityId, this.companyId).then(
        function (data) {
          this.campaignList = {
            Nova: data.filter(
              this.filterByPhase.bind(this, this.campaignPhasesText.Nova)
            ),
            EmAndamento: data.filter(
              this.filterByPhase.bind(this, this.campaignPhasesText.EmAndamento)
            ),
            Pausada: data.filter(
              this.filterByPhase.bind(this, this.campaignPhasesText.Pausada)
            ),
            Encerrada: data.filter(
              this.filterByPhase.bind(this, this.campaignPhasesText.Encerrada)
            ),
            Cancelada: data.filter(
              this.filterByPhase.bind(this, this.campaignPhasesText.Cancelada)
            ),
          };
        }.bind(this)
      );
    },
    filterByPhase(currentPhase, item) {
      return item.campaignPhaseId === currentPhase.id;
    },
    changeClass() {
      this.addClass = "no-footer";
      this.$emit("input", this.addClass);
    },
  },
};
</script>
